<template>
  <div v-if="isshowgrid">
    <va-card title="IPTV Bundle">
      <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Bundle Name</span>
            <va-input
              v-model.trim="bundle_name"
              placeholder="Enter Bundle Name"
              :error="!!bundleErrors.length"
              :error-messages="bundleErrors"
            />
            <span class="va-form-label va-form-required-label">Organization</span>
            <va-select
              placeholder="Select Organization"
              v-model="filter"
              textBy="short_code"
              searchable
              @input="getFilter(filter)"
              :options="orgArr"
              :error="!!orgErrors.length"
              :error-messages="orgErrors"
            />
            <div v-if="channelData.length > 0">
              <span class="va-form-label">Channels</span><br>
              <span class="text_left" v-for="(x,i) in channelData" :key="i">{{x.name}}{{((channelData.length-1)==i) ? '' : ', '}}</span><br>
            </div>
            <span class="va-form-label va-form-required-label">Packages</span>
            <va-select
              placeholder="Select Packages"
              v-model="pack"
              textBy="package_name"
              multiple
              @input="getPack(pack)"
              :options="packData"
              :error="!!packErrors.length"
              :error-messages="packErrors"
            />
            <h3>Total Amount Excluding GST {{amt}}</h3>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createBundle()">Create</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
Vue.use(vueResource)
export default {
  name: 'genres',
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + '/iptv_bundle').then(resp => {
      loader.hide()
      resp.body.map(function (prop, i) {
        prop.id = i + 1
      })
      this.orgArr = resp.body
      this.isshowForm = false
      this.isshowgrid = true
    })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      title: '',
      amt: '',
      filter: '',
      bundle_name: '',
      genresData: [],
      orgArr: [],
      pack: [],
      orgErrors: [],
      packData: [],
      packErrors: [],
      bundleErrors: [],
      channelData: [],
    }
  },
  computed: {
    formReady () {
      return !this.bundleErrors.length && !this.orgErrors.length && !this.packErrors.length
    },
  },
  methods: {
    getPack (data) {
      var amount = 0
      var ids = data.map(function (prop) {
        amount = amount + prop.amount
        return prop.package_id
      })
      this.amt = amount
      var payload = {
        ids: ids,
      }
      this.$http.post(config.menu.host + '/iptv_bundle/', payload).then(resp => {
        this.channelData = resp.body
      })
    },
    createBundle () {
      this.bundleErrors = this.bundle_name ? [] : ['Bundle Name is required']
      this.orgErrors = this.filter ? [] : ['Organization is required']
      this.packErrors = (this.pack.length > 0) ? [] : ['Package is required']
      if (!this.formReady) {
        return
      }
      this.pack.map(function (arg) {
        var category = ''
        arg.provider_category.map(function (thing) {
          if (category === '') {
            category = thing.id
          } else {
            category = category + ', ' + thing.id
          }
        })
        arg.provider_category = category
        delete arg.id
      })

      var payload = {
        bundle_name: this.bundle_name,
        price_one_month: this.amt,
        price_three_month: (this.amt * 3),
        price_six_month: (this.amt * 6),
        price_twelve_month: (this.amt * 12),
        moq: 1,
        add_on: true,
        iptv: true,
        bundlepackages: this.pack,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/iptv_bundle/create', payload).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({ text: response.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    getFilter (data) {
      let index = 0
      this.$http.get(config.menu.host + '/iptv_bundle/' + data.org_id).then(resp => {
        resp.body.map(function (item) {
          item.id = index++
        })
        this.packData = resp.body
      })
    },
    add () {
      this.title = 'Create IPTV Bundle'
      this.filter = ''
      this.pack = []
      this.packErrors = []
      this.orgErrors = []
      this.channelData = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.bundleErrors = []
    },
  },
}
</script>
<style type="text/css">
  .text_left {
    margin-left: 6px;
  }
</style>
